@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
body {
  background-color: #FAFAFA;
  color: #636b6f;
}

h1 {
  text-transform: uppercase;
  font-size: 32px;
}

.subheader {
  font-size: 16px;
}

.header {
  padding: 10px 0px;
  top: 0px;
  background: linear-gradient(#143F63, #112E44);
}

.nav-tabs-container {
  background-color: #fff;
  border-bottom: 1px solid #E6E6E6;
}

.nav-tabs {
  height: 100px;
  display: flex;
  align-items: center;
  border: none;
}

.nav-tabs a {
  height: 100px;
  color: #555555;
  font-size: 18px;
  height: 100%;
  padding-top: 40px;
}

.nav-tabs a:hover {
  text-decoration: none;
}

.nav-tabs-active {
  border-bottom: 3px solid #00E07A;
}

.nav-tabs-active a {
  font-weight: bold;
}

.progress_circle {
  width: 25px;
  height: 25px;
}

.fileListItemTemplate {
  min-height: 60px;
}

.border-right {
  border-right: 1px solid #E6E6E6;
}

.table th {
  border-top: none;
}

#emails {
  opacity: 0;
  position: relative;
  width: inherit;
  height: inherit;
  top: -145px;
}

.file-input-field {
  width: 570px;
  height: 260px;
  border: 3px dashed #E6E6E6;
}

.file-input-field img {
  height: 80px;
  width: 80px;
}

.file-input-field .description {
  position: relative;
  top: 25%;
  left: 25%;
}

.processedFileItem {
  margin-bottom: 25px;
}

.processedFileItem .card-title {
  padding: 5px;
}

.processedFileItem h5 {
  margin-bottom: 0;
}

.chart {
  float: right;
  width: 140px;
  height: 140px;
}

.fileListItem {
  border: none;
  margin-bottom: 5px;
}

.chart-label {
  display: inline;
}

.chart-label span {
  display: inline;
}

.chart-label .chart-label-error {
  padding-left: 20px;
  background-color: #E00022;
  margin-right: 5px;
}

.chart-label .chart-label-valid {
  padding-left: 20px;
  background-color: #00E07A;
  margin-right: 5px;
}

.chart-label .chart-label-deliverable {
  padding-left: 20px;
  background-color: #E09B00;
  margin-right: 5px;
}

.chart-label .chart-label-disposable {
  padding-left: 20px;
  background-color: #5600E0;
  margin-right: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 10px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 10px;
}

.border-top-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.border-bottom-none {
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.bookmark-link {
  color: #636b6f;
  text-decoration: none;
}

.bookmark-link i {
  color: orange;
}

.bookmark-link:hover {
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .header {
    display: none;
  }
  .nav-tabs-container {
    display: none;
  }
  .file-input-field {
    width: 90%;
    height: 160px;
  }
  .file-input-field img {
    height: 50px;
    width: 50px;
  }
  .file-input-field .description {
    position: relative;
    top: 10px;
    left: 10px;
  }
  .content {
    margin-top: 20px;
  }
}

@media (min-width: 767.98px) {
  .mobile-menu {
    display: none;
  }
  .content {
    margin-top: 100px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

#copyBtn {
  position: absolute;
  right: 15px;
  bottom: 7px;
}

.navbar {
  background: linear-gradient(#143F63, #112E44);
}
